<template>
  <ProductSearchItemTemplate
    :show-global-placeholders="props.showGlobalPlaceholders"
    :show-customer-data-placeholders="props.showCustomerDataPlaceholders"
  >
    <template
      v-if="props.product?.thumbnail?.src"
      #productImage
    >
      <NuxtLink
        :to="props.product?.link"
        class="mr-7 flex size-20 shrink-0 items-center justify-center"
        data-test="ui-product-search-item-image"
      >
        <UiImage
          v-if="props.product?.thumbnail?.src"
          v-bind="props.product.thumbnail"
          :loading="props.index > 2 ? 'lazy' : 'eager'"
          :fetchpriority="props.index > 2 ? 'low' : 'high'"
          class="mx-auto size-full object-contain object-center"
        />
      </NuxtLink>
    </template>
    <template
      v-if="product?.name"
      #productName
    >
      <NuxtLink
        :to="props.product?.link"
        class="mb-2 text-sm text-blue"
        data-test="ui-product-search-item-name"
      >
        {{ product?.name }}
      </NuxtLink>
    </template>
    <template
      v-if="props.product?.sku"
      #productSku
    >
      <span class="whitespace-nowrap">{{ props.product.sku }}</span>
    </template>
    <template
      v-if="props.product?.barcode"
      #productBarcode
    >
      <p class="-order-1 flex items-center whitespace-nowrap text-xs md:order-none">
        <UiIcon
          class="mr-1"
          name="barcode"
          :width="26"
          :height="10"
        />
        {{ props.product.barcode }}
      </p>
    </template>
    <template
      v-if="
        props.showCustomerProductCode
          && props.product?.customerProductCode
      "
      #customerProductCode
    >
      <p class="flex items-center whitespace-nowrap text-xs">
        <UiIcon
          class="mr-1"
          name="user-code"
          :width="16"
          :height="14"
        />
        {{ props.product.customerProductCode }}
      </p>
    </template>

    <template
      v-if="
        props.showStockQuantityLabel && props.product?.stockQuantityLabel
      "
      #stockQuantityLabel
    >
      <p class="flex items-center text-xs">
        <span
          class="mr-2 inline-block size-3 rounded-full bg-notification-success"
        />
        <HtmlParser
          v-if="props.product?.stockQuantityLabel"
          :html="props.product.stockQuantityLabel"
          tag="span"
        />
      </p>
    </template>
    <template
      v-if="props.showAvailabilityLabel && props.product?.availabilityLabel"
      #availabilityLabel
    >
      <UiProductAvailabilityLabel
        class="!hidden md:!flex"
        :is-quantity-more-than-zero="props.product.quantityMoreThanZero"
        :show-not-available-dot="props.showProductNotAvailableDot"
        :availability-label="props.product?.availabilityLabel"
      >
        <!-- If quantity of the product is more than 0, we add a comma and a space in order to separate
       'stockQuantityLabel' from 'availabilityLabel' -->
        <template
          v-if="props.product.quantityMoreThanZero"
          #middle-content
        >
          <span>,&nbsp;</span>
        </template>
      </UiProductAvailabilityLabel>
    </template>
    <template
      v-if="props.showPrices && typeof props.product?.price === 'number'"
      #prices
    >
      <slot
        name="prices"
        v-bind="{
          labels: props.labels,
          price: props.price,
          isPriceAssigned,
        }"
      >
        <template v-if="isPriceAssigned">
          <p
            v-if="props.labels?.priceForSinglePackage"
            class="whitespace-nowrap text-xs"
          >
            {{ props.labels.priceForSinglePackage }}
          </p>
          <p class="text-sm font-bold">
            {{ props.product?.price }}
          </p>
        </template>
        <template v-else>
          <p
            v-if="props.labels?.productNotAvailable"
            class="text-sm font-bold md:text-sm"
          >
            {{ props.labels.productNotAvailable }}
          </p>
        </template>
      </slot>
    </template>
    <template
      v-if="props.showAddToCartButton"
      #addToCartButton
    >
      <slot
        name="addToCartButton"
        v-bind="{
          product: props.product,
          label: props.labels?.addToCart ?? '',
        }"
      >
        <UiButton
          data-el="add-to-cart-btn"
          variant="cart"
          class="min-w-max flex-1 whitespace-nowrap !px-3.5"
          :title="props.labels?.addToCart ?? ''"
          :icon-dimensions="{
            width: 29,
            height: 32,
          }"
          icon-right="add-to-cart-plus-sign-outside"
          @click.stop.prevent="addToCart"
        />
      </slot>
    </template>
  </ProductSearchItemTemplate>
</template>

<script lang="ts" setup>
import UiButton from '../UiButton/UiButton.vue'
import UiIcon from '../UiIcon/UiIcon.vue'
import UiImage from '../UiImage/UiImage.vue'
import ProductSearchItemTemplate from '../templates/product/ProductSearchItemTemplate/ProductSearchItemTemplate.vue'
import { isPriceValid } from '../helpers/prices'
import UiProductAvailabilityLabel from '../UiProductAvailabilityLabel/UiProductAvailabilityLabel.vue'
import type {
  ProductSearchItemInterface,
  UiProductSearchItemProduct,
} from './UiProductSearchItem.types'

const props = withDefaults(defineProps<ProductSearchItemInterface>(), {
  product: null,
  showGlobalPlaceholders: false,
  showCustomerDataPlaceholders: false,
  showPrices: true,
  showCustomerProductCode: false,
  showStockQuantityLabel: false,
  showAvailabilityLabel: false,
  showAddToCartButton: false,
  showProductNotAvailableDot: true,
  price: null,
})

const emit = defineEmits<{
  (e: 'click:add-to-cart', item: UiProductSearchItemProduct): void
}>()

function addToCart() {
  emit('click:add-to-cart', props.product)
}

const isPriceAssigned = computed(() => isPriceValid(props.product?.price))
</script>
